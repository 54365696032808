import React from "react";
import PropTypes from "prop-types";
import FormDivider from "../../../../../components/divider/FormDivider";
import Typography from "@mui/material/Typography";


export default function SnaggingThankYou({multiPart}) {
  return (
    <>
      {multiPart && <FormDivider dividerText="Thank You" />}
      <Typography paragraph={true}>
      Thank you for your enquiry
      </Typography>
      <Typography paragraph={true}>
      Check your email

      </Typography>

      <Typography mt={2}>
      We have sent you an email with additional information about our services and prices etc.
      </Typography>
      <Typography paragraph={true}>
      Do not hesitate to get in touch on 01788 494400 or 01788 811 430 if you have any questions. You can also email us at info@newbuildinspections.com
      </Typography>
      <Typography paragraph={true}>
      Many Thanks.
      </Typography>
      <Typography paragraph={true}>
      New Build Inspections
      </Typography>
    </>
  );
}

// PropTypes
SnaggingThankYou.propTypes = {
  multiPart: PropTypes.bool,
};
